.aa-Form {
  width: 100%;
  display: flex;
  border-radius: 0.5rem;
}

.aa-Form:focus-within {
  border-color: #ffa14a !important;
  box-shadow:
    #feedde 0 0 0 1px,
    inset #feedde 0 0 0 1px;
  outline: medium none currentColor;
}

.aa-Input {
  flex: 1;
  border: none;
  padding: 1rem;
  font-size: 16px;
  outline: none;
  width: 100%;
}

.aa-SubmitIcon {
  height: 2rem;
  width: 2rem;
  fill: #ff7a00;
  margin-left: 0.8rem;
}

.aa-DetachedContainer {
  top: 15rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.aa-Panel {
  border-top-style: solid;
  width: 100%;
  border-radius: 0.5rem;
  border-width: 0.15rem;
  border-color: #f0f0f0;
  z-index: 106;
  position: fixed;
  top: 20rem !important;
}

.aa-PanelLayout {
  max-height: 60vh;
}

.aa-Source:last-child .aa-List {
  display: none;
}

.aa-Panel--scrollable {
  scrollbar-color: auto;
}

.aa-Panel--scrollable::-webkit-scrollbar {
  width: 7px;
}

.aa-Panel--scrollable::-webkit-scrollbar-track {
  background-color: transparent;
}

.aa-Panel--scrollable::-webkit-scrollbar-thumb {
  background-color: #888;
  border: none;
}

.aa-Panel .aa-List {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.aa-Panel .aa-Item[aria-selected='true'] {
  background-color: transparent;
}

.aa-Item mark {
  background-color: rgba(255, 122, 0, 0.2);
  font-weight: 500;
}

[data-autocomplete-source-id='querySuggestionsPlugin'] .aa-ItemContentTitle {
  background-color: transparent;
  font-weight: 500;
}

[data-autocomplete-source-id='querySuggestionsPlugin'] mark {
  background-color: transparent;
  font-weight: 400;
}

.aa-Panel .aa-SourceHeader {
  margin-left: 1rem;
}
.aa-Panel .aa-SourceHeaderTitle {
  padding-right: 1.5rem;
}
.aa-Panel .aa-SourceHeaderTitle {
  font-size: 1.8rem;
  color: #232323;
}

.aa-SourceHeaderLine {
  border-bottom: 1.5px solid #232323 !important;
}

.search-modal-fade {
  animation: fadeIn 0.35s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.search-modal-fade-out {
  animation: fadeOut 0.35s linear;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
